@import './responsive.scss';
:root {
    --color-main: #2124b1;
    --color-orange: #ff9900;
}
.carousel-item-padding-40-px {
    padding-right: 20px;
}

.media {
    margin-bottom: 50px;
    @include xs {
        margin-bottom: 30px;
    }
    &_title {
        color: var(--color-main);
        font-family: 'roboto';
        font-size: 26px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 100px;
        @include xs {
            margin-top: 30px;
        }
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include xs {
            //border-bottom: 1px solid var(--color-main);
        }
        p {
            // height: 1px;
            // width: 100%;
            // max-width: 400px;
            // background-color: rgb(108, 46, 243);
            // opacity: 0.2;
            // margin: auto;
            // @include xs {
            //     display: none;
            // }
            // @include sm {
            //     display: none;
            // }

            // @include lg {
            //     display: block;
            // }
        }
        p:nth-child(1) {
        }
        p:nth-child(2) {
        }
    }
    &_carousel {
        //padding-right: -20px;
        position: relative;
        &_group {
            width: 100%;
            position: relative;

            &:hover {
                cursor: pointer;
                .outer_circle {
                    stroke-dashoffset: 410;
                    transition:
                        stroke 0.7s 0.4s ease-out,
                        stroke-dashoffset 0.4s ease-out;
                }

                .inner-circle {
                    fill: #bf2428;
                    transition: fill 0.4s 0.3s ease-out;
                }

                .play {
                    fill: white;
                    transition: fill 0.4s 0.3s ease-out;
                }
            }
            img {
                width: 100%;
                max-width: 100%;
            }
            svg {
                cursor: pointer;
                width: 55px;
                height: 55px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .glightbox_video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .outer_circle {
                stroke-width: 3;
                stroke-dasharray: 410;
                stroke-dashoffset: 0;
                stroke-linecap: square;
                transition: all 0.4s ease-out;
            }

            .glightbox_video:hover .outer_circle {
                stroke-dashoffset: 410;
                transition:
                    stroke 0.7s 0.4s ease-out,
                    stroke-dashoffset 0.4s ease-out;
            }

            .glightbox_video:hover .inner-circle {
                fill: #bf2428;
                transition: fill 0.4s 0.3s ease-out;
            }

            .glightbox_video:hover .play {
                fill: white;
                transition: fill 0.4s 0.3s ease-out;
            }
        }
        p {
            font-size: 20px;
            font-family: 'roboto';
            text-transform: capitalize;
            color: var(--color-main);
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }
    }
}

// ghi de carousel
.react-multiple-carousel__arrow {
    top: 32%;
    &:hover {
        background-color: var(--color-main);
    }
}
.react-multiple-carousel__arrow--right {
    right: calc(2% + 1px);
    @include xs {
        right: 20px;
    }
}
.react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
}
