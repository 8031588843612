@import './responsive.scss';

:root {
    --color-main: #2124b1; //#0984e3
}
#b-5324 {
    @include xs {
        margin-top: 35px;
    }
    @include sm {
        margin-top: 60px;
    }
    @include md {
        margin-top: 0;
    }
    //height: 400px;
    position: relative;
    .bg-banner {
        // background-image: url('../assets/banner-bg/home.jpeg');
        // background-position: bottom;
        // background-repeat: no-repeat;
        // background-size: cover;
        height: 100%;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.802);
    }

    .bg-banner_content {
        position: absolute;
        width: 100%;
        max-width: 100%;
        top: 50%;
        transform: translateY(-50%);

        &_left {
            font-family: 'roboto';
            margin: auto;

            .h3-1 {
                color: #f8f8f8;
                font-weight: 600;

                @include xs {
                    margin-bottom: 5px;
                    font-size: 8px !important;
                }
                @include sm {
                    margin-bottom: 5px;
                    font-size: 10px !important;
                }
                @include lg {
                    margin-bottom: 20px;
                    font-size: 28px !important;
                }
            }
            .h3-2 {
                color: #f99b1c;
                margin-bottom: 20px;
                font-weight: 600;
                @include xs {
                    font-size: 8px !important;
                    margin-bottom: 5px;
                    max-width: 80%;
                }
                @include sm {
                    margin-bottom: 5px;
                    font-size: 10px !important;
                    max-width: 70%;
                }
                @include lg {
                    margin-bottom: 20px;
                    font-size: 28px !important;
                    max-width: 90%;
                }
            }
            .h3-3 {
                color: #f8f8f8;
                font-weight: 600;
                margin-bottom: 20px;
                max-width: 495px;
                line-height: 1.5;
                @include xs {
                    margin-bottom: 5px;
                    font-size: 8px !important;
                }
                @include sm {
                    margin-bottom: 5px;
                    font-size: 10px !important;
                    max-width: 60%;
                }
                @include lg {
                    margin-bottom: 20px;
                    font-size: 28px !important;
                    max-width: 80%;
                }
            }
            .tuvan {
                color: #f8f8f8;
                font-weight: 600;
                padding: 8px 20px;
                background-color: var(--color-main);
                border-radius: 6px;
                width: fit-content;
                text-transform: uppercase;
                font-size: 16px;
                cursor: pointer;
                @include xs {
                    font-size: 7px;
                    padding: 4px 12px;
                    border-radius: 3px;
                }
                @include sm {
                    font-size: 10px;
                    padding: 6px 18px;
                    border-radius: 4px;
                }
                @include lg {
                    font-size: 16px;
                    padding: 8px 20px;
                    border-radius: 6px;
                }
                &:hover {
                    color: var(--color-main);
                    background-color: #ecf0f1;
                    transition: all 0.5s ease;
                }
            }
        }
        &_right {
            position: relative;
            .wave-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: linear-gradient(to right, transparent 90%, #a7f4fe 20%);
                background-size: 50% 100%;
                animation: waveAnimation 5s linear infinite;
                filter: blur(100px);
            }

            .group-right {
                width: 100%;
                height: 100%;
                position: relative;

                &:hover {
                    box-shadow:
                        rgba(0, 0, 0, 0.25) 0px 54px 55px,
                        rgba(0, 0, 0, 0.12) 0px -12px 30px,
                        rgba(0, 0, 0, 0.12) 0px 4px 6px,
                        rgba(0, 0, 0, 0.17) 0px 12px 13px,
                        rgba(0, 0, 0, 0.09) 0px -3px 5px;
                    transition: 0.5s;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
            svg {
                cursor: pointer;
                z-index: 99999999;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include xs {
                    width: 30px;
                    height: 30px;
                }
                @include sm {
                    width: 40px;
                    height: 40px;
                }
                @include lg {
                    width: 60px;
                    height: 60px;
                }
            }
            .glightbox_video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 999999999;
            }

            .outer_circle {
                stroke-width: 3;
                stroke-dasharray: 410;
                stroke-dashoffset: 0;
                stroke-linecap: square;
                transition: all 0.4s ease-out;
            }

            .glightbox_video:hover .outer_circle {
                stroke-dashoffset: 410;
                transition:
                    stroke 0.7s 0.4s ease-out,
                    stroke-dashoffset 0.4s ease-out;
            }

            .glightbox_video:hover .inner-circle {
                fill: #bf2428;
                transition: fill 0.4s 0.3s ease-out;
            }

            .glightbox_video:hover .play {
                fill: white;
                transition: fill 0.4s 0.3s ease-out;
            }
        }
    }
}

@keyframes waveAnimation {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}
