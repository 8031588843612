:root {
    --color-red: #cf2e2e;
    --color-orange: #ff9900;
}


#nd-9653{
    font-family: roboto;
    // trả về mặc định
    ul { 
        list-style-type: disc; 
        list-style-position: inside; 
     }
     ol { 
        list-style-type: decimal; 
        list-style-position: inside; 
     }
     ul ul, ol ul { 
        list-style-type: circle; 
        list-style-position: inside; 
        margin-left: 15px; 
     }
     ol ol, ul ol { 
        list-style-type: lower-latin; 
        list-style-position: inside; 
        margin-left: 15px; 
     }
     em{
      font-style: italic !important;
   }
}