@import './responsive.scss';
:root {
    --color-main: #2124b1;
    --color-orange: #ff9900;
}
.lienhe {
    margin-top: 80px;
    @include xs {
        margin-top: 30px;
    }
    &_title {
        color: var(--color-main);
        font-family: 'roboto';
        font-size: 26px;
        font-weight: 600;
        text-transform: uppercase;
        //
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include xs {
            border-bottom: 1px solid var(--color-main);
        }
        p {
            @include xs {
                display: none;
            }
            @include sm {
                display: none;
            }

            @include lg {
                display: block;
            }
            height: 1px;
            width: 100%;
            max-width: 400px;
            background-color: rgb(108, 46, 243);
            margin: 0;
            opacity: 0.2;
        }
        p:nth-child(1) {
            margin-right: 40px;
        }
        p:nth-child(2) {
            margin-left: 40px;
        }
    }
    &_left {
        font-family: roboto;
        font-size: 16px;
        //padding-right: 30px;
        .anh {
            max-width: 200px;
            margin: 0 auto;
            img {
                // width: 100%;
                //object-fit: cover;
            }
            margin-bottom: 12px;
        }

        span {
            font-weight: bold;
        }
        p {
            line-height: 1.5;
            margin-bottom: 20px;
        }
        .web {
            cursor: pointer;
            &:hover {
                color: var(--color-main);
            }
        }
        &_tuvan {
            color: #f8f8f8;
            font-weight: 600;
            padding: 8px 20px;
            background-color: var(--color-main);
            border-radius: 6px;
            width: fit-content;
            text-transform: uppercase;
            font-size: 16px;
            cursor: pointer;
            font-family: roboto;
            // margin-top: 70px;
            @include xs {
                margin: 30px 0;
            }
            @include sm {
                margin: 30px 0;
            }
            @include lg {
                margin-top: 70px;
            }
            &:hover {
                color: var(--color-main);
                background-color: #ecf0f1;
                transition: all 0.5s ease;
            }
        }
    }
    &_right {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 20px;
        .custom-input {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ccc;
            padding: 8px 10px;
            border-radius: 5px;
            margin-bottom: 20px;
            input {
                width: 100%;
                border: none;
                outline: none;
            }
            img {
                width: 35px;
                margin-right: 7px;
            }
        }
        textarea {
            width: 100%;
            border: 1px solid #ccc;
            margin-bottom: 20px;
            outline: none;
            padding: 5px;
            margin-bottom: 40px;
        }
        .btn-lienhe {
            display: flex;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            .btn-lh {
                text-align: center;
                width: 100%;
                margin: 0 auto;
                font-size: 16px;
                font-family: roboto;
                font-weight: 600;
                text-transform: uppercase;
                border-radius: 3px;
                padding: 8px 12px;
                color: white;
                outline: none;
                background-color: var(--color-main);
                border: none;
                &:hover {
                    color: var(--color-main);
                    background-color: #ecf0f1;
                    transition: all 0.5s ease;
                }
            }
        }
    }
    .bando {
        margin-top: 50px;
    }
}
