@import './responsive.scss';

.carousel-item-padding-40-px {
    padding-right: 20px;
}
.title-tintuc {
    margin-top: 100px;
    @include xs {
        margin-top: 60px;
        //border-bottom: 1px solid var(--color-main);
    }
    text-align: center;
    text-transform: uppercase;
    color: var(--color-main);
    font-family: 'roboto';
    font-size: 26px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        height: 1px;

        background-color: rgb(108, 46, 243);
        opacity: 0.2;
        margin: auto;
        @include xs {
            display: none;
        }
        @include sm {
            display: none;
        }

        @include lg {
            display: block;
        }
    }
}

.custom-arrow-left {
    width: 40px;
    height: 40px;
    background-color: #95a5a6;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    color: white;
    border-radius: 50%;
    &:hover {
        background-color: var(--color-main);
    }
}
.custom-arrow-right {
    width: 40px;
    height: 40px;
    background-color: #95a5a6;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    color: white;
    border-radius: 50%;
    &:hover {
        background-color: var(--color-main);
    }
}
.card-slide {
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding: 12px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: 20px;
    //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow:
        rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: translateY(-8px);
    }
}

.card-img-holder {
    width: 100%;
    height: 200px;
    //transition: transform 0.3s ease-in-out;

    // &:hover {
    //     transform: scale(1.1);
    // }
}

.card-img-holder img {
    width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.blog-title {
    color: var(--color-main);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    font-size: 20px;
    margin-top: 10px;
    cursor: pointer;
    min-height: 48px;
    text-align: start;
}

.description {
    text-align: start;
    padding-top: 5px;
    color: #34495e;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    min-height: 77px;
}
.carousel .slide {
    text-align: start;
}
.blog-time-sl {
    text-align: unset;
    font-size: 0.8rem;
    color: #22215b;
    font-style: italic;
}

.options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
}

.options {
    .view {
        display: flex;
        align-items: center;
        span {
            margin-left: 5px;
        }
    }
}

.btn-doc {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    background: #eef7fe;
    color: var(--color-main);
    cursor: pointer;
    &:hover {
        background-color: #d9edfd;
    }
}
