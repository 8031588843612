@import './responsive.scss';
.show-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    //z-index: 9999;
    .blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-color: black;
        //z-index: 9999;
    }

    .video-yt {
        z-index: 999999;
        .react-player {
            @include xs {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
    .icon-x {
        position: absolute;
        right: 100px;
        top: 50px;
        font-size: 30px;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }
}
