@import './responsive.scss';
.row {
  display: flex;
  flex-wrap: wrap;
}
.ul-ft {
  padding: 0 !important;
}

.footer {
  background-color: var(--color-main);
  padding: 40px 0 10px;
  background-image: url('../assets/bg-white-footer.png');
  background-size: cover;
}

.sp-tm {
  cursor: pointer;
  border-bottom: 1px solid white;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  padding-left: 5px;
}
.footer-col {
  margin-bottom: 20px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li {
  font-size: 16px;

  color: #ecf0f1;
  text-decoration: none;
  font-weight: 300;
  display: block;
  transition: all 0.3s ease;
  font-weight: 600;
}
.footer-col ul li:hover {
  cursor: pointer;
  color: #ffffff;
  padding-left: 8px;
}
///// icon

.t {
}
.center-icon {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  @include xs {
    justify-content: space-around;
  }
  @include sm {
    gap: 10px;
    
  }
  .g-fb {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    &:hover {
      color: var(--color-main);
      background-color: white;
      transition: 0.3s;
    }
    .fb {
      font-size: 18px;
    }
    .zalo {
      font-size: 20px;
    }
  }
}
.ft-end {
  border-top: 1px solid #646464;
  color: #ecf0f1;
  font-weight: 600;
  // margin-top: 30px;
  padding-top: 20px;
}
