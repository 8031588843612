* {
    margin: 0;
    padding: 0;
}
.content-p {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .form-content {
        box-shadow:
        rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
        width: 400px;
        padding: 20px;
        .tm{
            font-size: 20px;
            color: red;
            text-align: center;
            margin-bottom: 20px;
        }
    }
}
.admin {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 20px;
    
}
