@import './responsive.scss';
.baiviet {
  margin-top: 50px;
  @include xs {
    margin-top: 30px;
  }
}
.group-search {
  display: flex;
  justify-content: space-between;
  .search {
    width: 400px;
    max-width: 100%;
  }
  @include xs {
    display: block;
    margin-bottom: 20px;
  }
}
.card {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid transparent;
  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
    cursor: pointer;
  }
}

.card-img-holder {
  width: 100%;
  height: 200px;
  position: relative;
}

.card-img-holder img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.blog-title {
  color: var(--color-main);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  font-size: 20px;
  margin-top: 10px;
  cursor: pointer;
  min-height: 50px;
}

.description {
  padding-top: 5px;
  color: #34495e;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  min-height: 77px;
}

.blog-time {
  font-size: 0.8rem;
  color: #22215b;
  font-style: italic;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.options {
  .view {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}

.btn-doc {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  background: #eef7fe;
  color: var(--color-main);
  cursor: pointer;
  &:hover {
    background-color: #d9edfd;
  }
}
