@import './animation.scss';
@import './responsive.scss';

:root {
    --color-main: #2124b1;
}
.container {
    max-width: 1200px !important;
    margin: 0 auto;
}
.active-menu {
    //border-bottom: 3px solid var(--color-main);
    color: var(--color-main);
}

.header-sticky {
    position: fixed;
    //box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;    animation: slideDown 0.5s ease-in-out;
    margin: 0 auto;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
}

.header-1 {
    padding: 10px 0;
    background-color: var(--color-main);
    color: white;
    font-weight: 600;
    font-size: 12px;
    font-family: 'roboto';
    &_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
    }
}
.header-2 {
    padding: 10px 0;
    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            cursor: pointer;
            max-width: 150px;
            img {
            }
        }
        .menu {
            padding-top: 20px;

            .parent-ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                font-family: 'roboto';
                font-weight: 600;
                color: #7d7d7d;
                .arrow-header {
                    font-size: 18px;
                }

                .parent-li {
                    text-transform: uppercase;
                    font-size: 15px;
                    border-bottom: 3px solid transparent;
                    &:hover {
                        cursor: pointer;
                        border-bottom: 3px solid var(--color-main);
                        transition: 0.5s;
                    }
                }
                .dropdown-child {
                    display: none;
                }
                .drop-parent {
                    position: relative;
                    &:hover {
                        .dropdown-child {
                            z-index: 999;
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 112%;
                            padding: 20px;
                            width: 240px;
                            background-color: #ffffff;
                            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                            li {
                                font-size: 16px !important;
                                text-transform: none !important;
                                font-family: sans-serif !important;
                                color: #777;
                                padding: 7px 0;
                                line-height: 1.2;
                                &:hover {
                                    transition: all 0.5s ease;
                                    padding-left: 10px;
                                    color: var(--color-main);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/// responsive moblile
///
.t {
}
.ant-menu-light .ant-menu-item-selected {
    background-color: var(--color-main) !important;
    color: white;
}
.header-res {
    @include xs {
        display: flex;
    }
    @include sm {
        display: flex;
    }
    @include lg {
        display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999999;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .dot-mb {
        position: relative;
        img {
            width: 30px;
        }
        z-index: 99999999;
    }
    .logo-res {
        cursor: pointer;
        max-width: 100px;
        img {
            width: 100%;
        }
    }
    .menu-res {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9999;
        width: 100%;
    }
}

.header-2_content {
    @include xs {
        display: none;
    }
    @include sm {
        display: none;
    }
    @include lg {
        display: flex;
    }
}
