@import './responsive.scss';
:root {
    --color-main: #2124b1;
    --color-orange: #ff9900;
}

#gt-6978 {
    margin-top: 100px;
    @include xs {
        margin-top: 50px;
    }
    .thong-tin {
        padding: 0px 20px;
        &_title {
            color: var(--color-main);
            font-weight: 600;
            font-family: 'roboto';
            font-size: 20px;
            padding-bottom: 10px;
            border-bottom: 2px solid var(--color-main);
        }
        &_des {
            font-family: 'roboto';
            font-size: 16px;
            line-height: 1.5;
            color: #2c2a2a;
           
        }
        &_btn {
            @include xs {
                //display: none;
            }
            margin-top: 50px;
            color: #f8f8f8;
            font-weight: 600;
            padding: 8px 20px;
            background-color: var(--color-main);
            border-radius: 6px;
            width: fit-content;
            text-transform: uppercase;
            font-size: 16px;
            font-family: roboto;
            cursor: pointer;
            &:hover {
                color: var(--color-main);
                background-color: #ecf0f1;
                transition: all 0.5s ease;
            }
        }
    }
    .thuonghieu {
        img {
            width: 100%;
        }
        p {
            color: var(--color-main);
            text-align: center;
            font-weight: 600;
            line-height: 1.3;
            font-family: 'roboto';
        }
    }
}

#tk-5234 {
    @include xs {
        display: none;
    }
    @include sm {
        display: none;
    }
    @include lg {
        display: block;
    }
    margin-top: 100px;
    height: 200px;
    width: 100%;
    position: relative;
    .bg-tk {
        // background-image: url('../assets/banner-bg/banner-thong-ke.jpeg');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
        // height: 100%;
        // width: 100%;
        height: 100%;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.802);
    }
    .thong-ke {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'roboto';
        font-weight: 600;

        p:nth-child(1) {
            color: var(--color-orange);
            // color: var(--color-main);
            font-size: 26px;
            text-align: center;
        }
        p:nth-child(2) {
            color: white;
            margin-top: 20px;
            font-size: 18px;
            line-height: 1.5;
            text-align: center;
        }
    }
}
