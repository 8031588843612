@import './responsive.scss';
.baiviet-detail {
    margin-top: 50px;
    @include xs {
        margin-top: 30px;
    }
}

.g-detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 15px;
    .title-baiviet {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        //width: 80%;
        max-width: 75%;
        font-family: 'roboto';
        @include xs {
            width: 100%;
            max-width: 100%;
        }
    }
    .v-t {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .view {
            font-family: roboto;
            span {
                margin-left: 5px;
            }
            @include xs {
                display: none;
            }
            @include sm{
                display: none;
            }
            @include lg{
                display: block;
            }
        }
        .time-baiviet {
            font-style: italic;
            color: #7b7979;
            margin-left: 50px;
            @include xs {
                display: none;
            }
        }
    }
}

.noidung {
    font-family: roboto;
    margin-bottom: 20px;
    overflow: hidden;
    iframe {
        max-width: 100%;
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    ol {
        list-style-type: decimal;
        list-style-position: inside;
    }
    ul ul,
    ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin-left: 15px;
    }
    ol ol,
    ul ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: 15px;
    }
    em {
        font-style: italic !important;
    }
}

.br-home {
    &:hover {
        cursor: pointer;
        color: var(--color-main);
    }
}
.breadcrumb-ov {
    font-size: 18px !important;
}
